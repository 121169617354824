import * as React from "react";
import Layout from "../../components/Layout";
import GeneralTab from "../../components/HTabs";
import { Seo } from "../../components/seo";
import Service from "../../components/Services";
import Counter from "../../components/Counter";
import FAQAccord from "../../components/GFlush";
// import Hero from "../../components/Hero";
import About from "../../components/About";
import Document from "../../components/Document";
import Cta from "../../components/Cta";
import Package from "../../components/GSTTemp/Package";

export default function PersonCompany() {
  // Banner slider
  // const PersonSlider = [
  //   {
  //     header_color: false,
  //     content_color: false,
  //     marginTop: 30,
  //     background: `linear-gradient(139.4deg, rgb(198, 243, 242) 28.54%, rgb(181, 201, 253) 104.43%)`,
  //     header: `One Person Company Registration`,
  //     content: `Apply for One Person Company Registration (OPC) Registration Online with TODAYFILINGS Experts`,
  //     image: "/imgs/business/sliders/one-person.png",
  //     alt_tag: "Best Online One Person Company Registration Service Near Bangalore",
  //   },
  //   {
  //     header_color: false,
  //     content_color: false,
  //     marginTop: 95,
  //     background: `linear-gradient(139.4deg, rgb(207, 249, 216) 28.54%, rgb(243, 238, 160) 104.43%)`,
  //     header: `Online One Person Company Registration`,
  //     content: `Best One person Company Registration Service in Hosur`,
  //     image: "/imgs/business/sliders/onecompslider.png",
  //     alt_tag: "Best Online One Person Company Registration in Hosur",
  //   },
  //   {

  //     background: `linear-gradient(139.4deg, rgb(252, 237, 216) 28.54%, rgb(245, 197, 198) 104.43%)`,
  //     marginTop: 95,
  //     header: `One Person Company Registration Service`,
  //     content: `Best online One person Company(OPC) Registration Service`,
  //     image: "/imgs/business/sliders/one-slider.png",
  //     alt_tag: "Best Online One Person Company Registration in Hosur",
  //   },
  // ];

  /* Package Data */
  const PackageCompData = {
    header: `One Person Company Registration`,
    buyBtnLink: `#pricing-buy`,
    price: `6499`,
    f_price: `9999`,
    content: [
      {
        points: `PAN & TAN Registration `,
        icon: '/imgs/icons/bregistration/pancard.png',
        alt_tag: 'PAN & TAN Registration',
        background: '',
        plus: '+',
      },
      {
        points: `PF + ESIC + Professional Tax`,
        icon: '/imgs/icons/bregistration/register.png',
        alt_tag: 'TODAYFILINGS ITR E-filing service',
        background: 'bg-f78acb',
        plus: '+',
        plus_dnone: 'd-none d-md-block',
      },
      {
        points: `1yr
        hosting + 10 emails`,
        icon: '/imgs/icons/hosting.png',
        alt_tag: 'Domain Name',
        background: 'bg-eb6b3d',
        plus: '+',
      },
      {
        points: `Insurance for 2
         Partners  `,
        icon: '/imgs/icons/consultant.png',
        alt_tag: 'Tax Consultancy Service',
        background: 'bg-c679e3',
      },
    ],
  };

  /* About Data Without Form*/
  const OpcAboutData = {
    title: `One Person Company`,
    sub_title: `One Person Company Registration in just 7 Days`,
    paragraph: `One Person Company is a distinct legal person with an
    unending succession. It is necessary to submit an online
    application for One Person Company registration in India in
    accordance with the Companies Act, 2013, compliance
    requirements and regulatory norms and guidelines of the
    Ministry of Corporate Affairs (MCA). One Person Companies
    (OPCs) are legal entities that can be set up with just 1
    owner, who can serve as both the company's shareholder and
    director.`,
    header: `Our Features in OPC Registration in India`,
    points: [
      `An Immediate Nominee`,
      `One Director`,
      `one ShareHolder`,
      `Fewer Compliances`,
    ],
  };

  /* Service Data */
  const PersonComp = {
    header: `One Person Company Registration Online`,
    content: `One Person Company is a distinct legal person with an unending
    succession. It is necessary to submit an online application
    for One Person Company registration in India in accordance
    with the Companies Act, 2013.`,
    image: '/imgs/business/oneperson.png',
    alt_img: 'TODAYFILINGS - Online One Person Company Registration',
    points: [
      `Limited Liability`,
      `Existence Continues`,
      `Registration With Least Requirements`,
      `More Benefits For Small Scale Industries`,
      `Ease In Funding`,
      `Better Reputation`,
    ],
  };

  /* FaQ */
  var OneFAQ = [
    {
      header: 'What is DSC?',
      body: [
        {
          content: ` The DSC is nothing but a Digital Signature Certificate issued by
          certifying authorities (TCS and n-Code) using that certificate you
          can sign electronic documents. As all documents need a DSC to start
          up a Private Limited company in India.`,
          icon: false,
        },
      ],
    },
    // 2
    {
      header: 'What is DIN?',
      body: [
        {
          content: ` DIN is a short form of Director Identification Number and it is
          issued by Registration of the Companies ROC which permits the
          director to work in the company.`,
          icon: false,
        },
      ],
    },
    {
      header: 'What is a One Person Company (OPC)?',
      body: [
        {
          content: `Company which has only one person as a director and member and this
          definition of OPC is declared in Section 2(62) of the Companies Act,
          2013.`,
          icon: false,
        },
      ],
    },
    // 3
    {
      header: 'Who can form or incorporate a OPC?',
      body: [
        {
          content: `According to Rule 3(1) of the Companies (Incorporation) Rules 2014,
          only a person who is residing in India and an Indian Citizen shall
          be eligible to start or form an OPC.`,
          icon: false,
        },
      ],
    },
    // 4
    {
      header: 'Is One Person Company structure suitable for small business?',
      body: [
        {
          content: ` Yes! Without a doubt, the One Person Company is appropriate for
          small firms with annual revenues under Rs. 2 crore.`,
          icon: false,
        },
      ],
    },
    {
      header: 'Who is eligible to become a member of an OPC?',
      body: [
        {
          content: ` A natural person who is an Indian citizen whether
          resident in India or otherwise shall be eligible to incorporate a One Person Company.`,
          icon: false,
        },
      ],
    },
    {
      header: ' Is it mandatory for a member of a OPC to appoint a nominee?',
      body: [
        {
          content: `Yes, it is mandatory for a member of a OPC to appoint a nominee.`,
          icon: false,
        },
      ],
    },
    {
      header: '  Can a minor become a member or nominee of an OPC?',
      body: [
        {
          content: `A minor cannot become the member or nominee of an OPC or can hold shares of a OPC with beneficial interest.`,
          icon: false,
        },
      ],
    },
    {
      header: 'Is it mandatory for a OPC to hold an AGM?',
      body: [
        {
          content: `It is not mandatory for a OPC to hold an annual general meeting.`,
          icon: false,
        },
      ],
    },
    {
      header: 'Who is required to verify the annual return of an OPC?',
      body: [
        {
          content: `Annual Return of an OPC shall be signed by the Company
           Secretary or where there is no Company Secretary by the director of a company.`,
          icon: false,
        },
      ],
    },
  ];

  /* Tabs Data */
  const OneTabsData = {
    id: 'features',
    heading: 'Registration Procedure',
    paragraph: `In India, one person companies can be registered online and in
    accordance with the 2013 Companies Act.`,
    body: [
      {
        tab_title: 'Identification',
        tab_content: [
          {
            content_title: 'Step-1',
            content_paragraph:
              'Applying for Digital Signature Certificate or DSC.',
            points: [],
          },
          {
            content_title: 'Step-2',
            content_paragraph: `The SPICe+ form may be used to submit an application for a
          DIN, or Directors Identification Number.`,
            points: [],
          },
        ],
        content_img: '/imgs/business/identification-one.png',
        alt_img:
          'TODAYFILINGS is the Best One Person Company Registration Service in Hosur',
      },
      {
        tab_title: 'Document Drafting',
        tab_content: [
          {
            content_title: 'Step-3',
            content_paragraph: `Selecting a distinctive name for the business and
          obtaining name approval it.`,
            points: [],
          },
          {
            content_title: 'Step-4',
            content_paragraph: ` Rechecking all supporting documentation, including ID and
          address evidence, before submission.`,
            points: [],
          },
        ],
        content_img: '/imgs/business/documents-one.png',
        alt_img:
          'TODAYFILINGS is the Best One Person Company Registration Service near Bangalore',
      },
      {
        tab_title: 'Pan Certificate',
        tab_content: [
          {
            content_title: 'Step-5',
            content_paragraph: `submitting an application form and the necessary
          supporting documentation.`,
            points: [],
          },
          {
            content_title: 'Step-6',
            content_paragraph: `For the purpose of registering the company, an application
          must be made to get a TAN and PAN.`,
            points: [],
          },
        ],
        content_img: '/imgs/business/pan.png',
        alt_img: 'Best One Person Company Registration Service in India',
      },
      {
        tab_title: 'Bank Account',
        tab_content: [
          {
            content_title: 'Step-7',
            content_paragraph: `Opening a current account in the company's name at a bank
          that has been authorized.`,
            points: [],
          },
          {
            content_title: 'Step-8',
            content_paragraph: `The Registrar of Companies would issue a certificate of
          incorporation that contained the CIN number following the
          authorities' verification of all the paperwork and forms.`,
            points: [],
          },
        ],
        content_img: '/imgs/business/bank.png',
        alt_img: 'Best One Person Company Registration Service',
      },
    ],
  };

  /* Document Section Data */
  const OpcDocData = {
    id: 'opc-doc',
    heading: `One Person Company`,
    sub_heading: `Salient Features of One Person Company Registration in India`,
    doc_contents: [
      {
        col: `col-lg-4 col-md-6`,
        delay: '100',
        img: `/imgs/icons/bregistration/liabilities.png`,
        alt_tag: `Limited Liability`,
        header: `Liability`,
        description: `Ccorporation that has been registered as a one-person
        company benefits from the restricted liability of its
        directors.`,
      },
      {
        col: `col-lg-4 col-md-6`,
        delay: '200',
        img: `/imgs/icons/bregistration/compliant.png`,
        alt_tag: `Legal Entity`,
        header: `Legal Entity`,
        description: `One Person Company (OPC) is a distinct legal entity, just
        like private and public limited businesses and directors and shareholders can be same person.`,
      },
      {
        col: `col-lg-4 col-md-6`,
        delay: '300',
        img: `/imgs/icons/bregistration/compliances.png`,
        alt_tag: `Compliances`,
        header: `Fewer Compliances`,
        description: `A one-person Company corporation registration has less compliance
        than a private limited company registration compliance.`,
      },
      {
        col: `col-lg-4 col-md-6`,
        delay: '400',
        img: `/imgs/icons/bregistration/director.png`,
        alt_tag: `Director`,
        header: `One Director`,
        description: `One person company should have minimum one
        director and the director should be an indian resident
        since they must have spent at least 182 days.`,
      },
      {
        col: `col-lg-4 col-md-6`,
        delay: '500',
        img: `/imgs/icons/bregistration/nominee.png`,
        alt_tag: `Nominees`,
        header: `Immediate Nominee`,
        description: `Even if a company can be founded by just one person, there
        should be a nominee who can assume the shareholder's
        obligations in the worst-case death.`,
      },
      {
        col: `col-lg-4 col-md-6`,
        delay: '600',
        img: `/imgs/icons/bregistration/share.png`,
        alt_tag: `Share Holder`,
        header: `Single Share Holder`,
        description: `To create a One Person Company in India, only one
        shareholder is needed. The stockholder must be an Indian
        resident since they must have spent at least 182 days.`,
      },
    ],
  };

  return (
    <div>
      <Seo
        title='One Person Company'
        keywords='Keywords'
        description='Description'
        name='TODAYFILINGS'
        type='Website'
        author='TODAYFILINGS'
      />
      <Layout>
        <main id='main'>
          {/* <Hero sliders={PersonSlider} /> */}
          <Package packageData={PackageCompData} />

          <About about_data={OpcAboutData} />

          <Counter />

          <Service item={PersonComp} />

          <Document docSecData={OpcDocData} />

          <GeneralTab HTabsData={OneTabsData} />

          <Cta />
          <FAQAccord items={OneFAQ} />
        </main>
      </Layout>
    </div>
  );
}
